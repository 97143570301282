<script>
    export default {
        components: {},
        methods: {
            logout: function () {
                this.$session.destroy()
                localStorage.removeItem("token")
                this.$router.push('/p/login')
            }
        },
        mounted() {
            this.logout()
        }
    }
</script>